<template>
  <div class="page">
    <!-- 营业执照 -->
    <div class="certification_img_box">
      <div class="box_top flex-r-sb-c">
        <div class="img_name">营业执照</div>
        <!-- <div class="img_expale flex-r-c-c">查看示例
          <van-icon name="question-o" />
        </div> -->
      </div>
      <div v-if="disabled ">
        <van-image width="6.74rem" height="3.49rem" :src="certificationForm.businessLicenseUrl" />
      </div>
      <div v-else>
        <van-uploader v-model="fileList01" :after-read="afterRead01" :max-count="1">
          <van-image v-if="fileList01.length===0" width="6.29rem" height="3.68rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification01.png"
          />
        </van-uploader>
        <div v-if="fileList01.length === 0" class="up_tip">点击上传营业执照</div>
      </div>
      <van-form :disabled="disabled" @submit="onSubmit">
        <van-field v-model="certificationForm.gongshangName" label="工商名称" name="gongshangName" placeholder="请输入工商名称" />
        <van-field v-model="certificationForm.legalPerson" label="法人姓名" name="legalPerson" placeholder="请输入法人姓名" />
        <van-field v-model="certificationForm.businessLicense" label="三证合一码" name="businessLicense"
                   placeholder="请输入三证合一码"
        />
        <van-field v-model="certificationForm.companyAddress" label="注册地址" name="businessLicense"
                   placeholder="请输入注册地址"
        />
        <van-field v-model="certificationForm.accountManager" label="客户经理" name="accountManager"
                   placeholder="请输入客户经理"
        />
        <div class="login_sub">
          <van-button round block :loading="loading" type="info" loading-text="登录中..." native-type="submit"
                      color="#0767ab" style="font-size: 0.43rem;" class="next_btn position_fixed"
          >立即认证
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import './index.scss'
import { upProToOss } from '@/utils/ali-oss'
import { common_ocr } from '@/api/public'
import { user_addGongShangInfo, user_userInfo } from '@/api/user'
export default {
  name: 'Certification',
  data() {
    return {
      step: 2,
      certificationShow: true,
      fileList01: [],
      loading: false,
      certificationForm: {},
      disabled: false

    }
  },
  created() { this.getInfo() },
  methods: {
    getInfo() {
      user_userInfo().then(res => {
        if (parseInt(res.data.wholesaleCertificationType) === 1) {
          this.disabled = true
          this.certificationForm = res.data
        }
      })
    },
    // OCR识别
    OCR(url) {
      this.$toast.loading({ message: 'OCR识别中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      common_ocr(url).then(res => {
        var vetLicense = this.certificationForm.vetLicense || ''
        this.$set(res.data, 'vetLicense', vetLicense)
        if (res.data) { this.certificationForm = res.data }
        this.$toast.clear()
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 图片上传
    afterRead01(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      upProToOss(file).then(res => {
        this.fileList01 = []
        this.fileList01.push({ url: res, isImage: true })
        this.$set(this.certificationForm, 'businessLicenseUrl', res || '')
        this.OCR(res)
      })
    },
    // 点击登录
    onSubmit(values) {
      this.loading = true
      user_addGongShangInfo(this.certificationForm).then(res => {
        this.$toast({ message: '已提交审核！', duration: 3 * 1000 })
        this.$router.push('/publictips/success?type=0')
        this.loading = false
      })
    }
  }
}
</script>
